<template>
  <div v-if="readonly">
    <label v-if="label" class="col-form-label">{{ localizedLabel }}</label>
    <p class="font-bold">{{ countryName }}</p>
  </div>
  <div v-else>
    <b-form-group :disabled="readonly" :label="localizedLabel" :label-for="$attrs.id">
      <vue-country-code @onSelect="onChange" :dropdown-options="{ disabledDialCode: true }" :default-country="value" :preferred-countries="['us']" />
    </b-form-group>
  </div>
</template>
<script>

import VueCountryCode from "vue-country-code"

export default {
  components: {
    VueCountryCode
  },
  props: {
    // must be included in props
    value: {
      type: null
    },
    countryName: {
      type: String
    },
    label: {
      type: String,
      default: 'Country'
    },
    cssClass: {
      type: String,
      default: 'form-control'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  mounted() {
  },
  computed: {
    hasValue() {
      return (this.innerValue && (this.innerValue.length > 0) )
    },
    localizedLabel() {
      if (this.$i18n) {
        return this.$t(this.label)
      }
      return this.label
    }
  },
  watch: {
    value(newV) {
      this.innerValue = newV
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.iso2)
      this.$emit('update:country', e.name)
    }
  }
}
</script>
